/* Minimum Size Responsive */
rem();
window.addEventListener("resize", rem);

/* Iphone Check */
if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
  document
    .getElementsByName("viewport")[0]
    .setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
} else {
  document
    .getElementsByName("viewport")[0]
    .setAttribute("content", "width=device-width, initial-scale=1.0");
}

/* Device Check */
if (
  navigator.userAgent.match(
    /Android|Mobile|iP(hone|od|ad)|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
  )
) {
  document.querySelector("body").classList.add("dv_mobile");
} else {
  document.querySelector("body").classList.add("dv_pc");
}

/* Minimum Size Responsive */
function rem() {
  let breakPoint = 1440;
  let rem = document.documentElement.clientWidth / breakPoint;
  document
    .querySelector(":root")
    .setAttribute("style", "font-size:" + rem * 10 + "px;");
}
