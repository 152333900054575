<template>
  <router-view />
</template>

<script>
import "@/assets/js/promotion/pincrux/common";

export default {
  name: "PincruxLayout",
};
</script>

<style scoped>
@import "~@/assets/fonts/myriadPro.css";
@import "~@/assets/css/promotion/pincrux/normalize.css";
@import "~@/assets/css/promotion/pincrux/common.css";
@import "~@/assets/css/promotion/pincrux/content.css";
</style>
